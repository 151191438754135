<template>
  <div>
    <Orders />
  </div>
</template>

<script>
import Orders from "../components/ReportCenter/Orders";
export default {
  components: {
    Orders,
  },
};
</script>

<style></style>
